import React from 'react'
import classnames from 'classnames'
import styles from './Icon.module.css'

export const Icon = ({ className, inheritColor, shifted }) => {
  const classNames = classnames(styles.icon, className, {
    [styles.iconInheritColor]: inheritColor,
    [styles.iconShifted]: shifted
  })
  return <i className={classNames} />
}