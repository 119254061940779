import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { siteDescription, siteKeywords, siteTitle, siteUrl } from '../../../site-config'
import './bootstrap-grid.css'
import './global.css'

import styles from './Page.module.css'

export const Page = ({ location, children }) => {
  const printMode = location.search.startsWith('?printMode')
  let sectionClasses = ''
  let bodyClasses = styles.page
  if (printMode) {
    sectionClasses = 'sheet padding-10mm'
    bodyClasses = 'A4'
  }

  return (
    <section className={sectionClasses}>
      <article>
        <Helmet
          title={siteTitle}
          meta={[
            { name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
            { property: 'og:url', content: siteUrl },
            { property: 'og:title', content: siteTitle },
            { property: 'og:description', content: siteDescription },
          ]}
          link={[
            { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css' },
            {
              rel: 'stylesheet',
              href: 'https://use.fontawesome.com/releases/v5.8.1/css/all.css',
              integrity: 'sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf',
              crossorigin: 'anonymous'
            }
          ]}
          bodyAttributes={{
            class: bodyClasses
          }}
        />
        <nav className={styles.navigation}>
          <Link to='/' title='Curriculum Vitae' className={styles.first}>CV</Link>|
          <Link to='/portfolio' title='Portfolio of selected projects'>Portfolio</Link>|
          <Link to='/links' title='My Links'>Links</Link>
        </nav>
        {children}
      </article>
    </section>)
}
