import React from 'react'
import styles from './ContactEntry.module.css'
import { Icon } from './Icon'

export const ContactEntry = ({ url, iconClassName, label, text, big = false }) => {
  const entryClasses = [styles.entry]
  const linkClasses = [styles.link]
  const iconClasses = [iconClassName]

  if (big) {
    entryClasses.push(styles.entryBig)
    linkClasses.push(styles.linkBig)
    iconClasses.push(styles.iconBig)
  }

  return (
    <div className={entryClasses.join(' ')}>
      <a href={url} className={linkClasses.join(' ')} title={label}><Icon className={iconClasses.join(' ')} />{text}</a>
    </div>
  )
}
