const path = require('path')

module.exports = {
  name: '',
  siteTitle: 'Tomasz Rozmus CV', // Site title.
  siteUrl: '', // Domain of your website without pathPrefix.
  siteKeywords: 'tomasz rozmus cv portfolio',
  siteDescription: 'Tomasz Rozmus CV', // Website description used for RSS feeds/meta description tag.
  googleAnalyticsID: null, // GA tracking ID.
  userName: 'Tomasz Rozmus', // Username to display in the author segment.
  userJobTitle: 'Full-stack web developer',
  userTwitter: null, // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: 'Cracow, Poland', // User location to display in the author segment.
  userLocationUrl: 'https://www.google.com/maps/place/Krak%C3%B3w,+Polska/@50.0464268,19.7240224,10z/data=!3m1!4b1!4m5!3m4!1s0x471644c0354e18d1:0xb46bb6b576478abf!8m2!3d50.0646501!4d19.9449799', // User location to display in the author segment.
  userDescription: 'I’m a full-stack node.js developer, specialized in React applications, experienced in CI/CD stuff and familiarized with Ethereum blockchain.', // Website description used for RSS feeds/meta description tag.
  userPhone: '+48 664 194 420',
  userEmail: 'tomasz.rozmus@gmail.com',
  userLinks: [
    {
      label: 'GitHub',
      url: 'https://github.com/xnyl',
      shortUrl: 'github.com/xnyl',
      iconClassName: 'fab fa-github'
    },
    {
      label: 'Gitlab',
      url: 'https://gitlab.com/tomasz.rozmus',
      shortUrl: 'gitlab.com/tomasz.rozmus',
      iconClassName: 'fab fa-gitlab'
    }, {
      label: 'StackOverflow',
      url: 'https://stackoverflow.com/users/story/792026?view=Timeline',
      shortUrl: 'stackoverflow.com/users/792026',
      iconClassName: 'fab fa-stack-overflow'
    },
    {
      label: 'LinkedIn',
      url: 'https://www.linkedin.com/in/tomaszrozmus',
      shortUrl: 'linkedin.com/in/tomaszrozmus',
      iconClassName: 'fab fa-linkedin'
    }
  ],
  skills: [
    {
      name: 'React',
      rating: 10
    },
    {
      name: 'Redux',
      rating: 9
    },
    {
      name: 'NodeJs',
      rating: 8
    },
    {
      name: 'Express',
      rating: 8
    },
    {
      name: 'NestJS',
      rating: 8
    },
    {
      name: 'Typescript',
      rating: 9,
    },
    {
      name: 'HTML',
      rating: 7,
    },
    {
      name: 'CSS',
      rating: 7,
    },
    {
      name: 'Gitlab CI',
      rating: 8,
    }
  ],
  languages: [
    {
      name: 'Polish',
      level: 'Native'
    },
    {
      name: 'English',
      level: 'Proficient'
    }
  ],
  experience: [
    {
      position: 'Full-stack Developer - Consultant',
      company: 'Freelance',
      startDate: 'Dec 2019',
      endDate: 'now',
      location: 'Remote',
      overview: 'Consultation and development for various clients:',
      projects: [
        {
          name: 'TalentAlpha',
          description: 'Frontend for revolutionary IT talent management app.',
          website: 'https://talent-alpha.com/',
        },
        {
          name: 'Undisclosed Client',
          description: 'Creating application for football academies. Complete solution involved. From design adaptation, programming, e2e testing to DevOps CI/CD automation.',
        },
      ],
      technologies: ['Typescript', 'React', 'Redux', 'Redux-Observable', 'RxJs', 'Material UI', 'node.js', 'NestJS', 'Postgres', 'Cypress', 'Gitlab CI', 'Docker', 'Kubernetes', 'Emotion.js', 'AndDesign']
    },
    {
      position: 'Full-stack Developer',
      company: 'Rumble Fish Blockchain Development',
      startDate: 'Dec 2017',
      endDate: 'Dec 2019',
      location: 'Remote, Cracow',
      overview: 'Development of various products for external clients:',
      projects: [
        {
          name: 'The Ocean',
          description: 'Development of high performance, institution-grade, decentralized platform for Ethereum and ERC20 token trading. Taking part in both frontend and backend architecture design.',
          website: 'https://theocean.trade/',
        },
        {
          name: 'Wag Hotels',
          description: 'Creating CMS for one of California\'s top pet hotels network. High SEO and performance indicators were required.',
          website: 'https://www.waghotels.com/',
          websiteName: 'https://www.waghotels.com/san-francisco/'
        },
        {
          name: 'ConnectYourCare',
          description: 'Leading a group of 4 developers to create internal UI Framework and complex product configurator for a client - administrator of healthcare accounts like HSA, HRA and FSA.',
        }
      ],
      technologies: ['React', 'node.js', 'express', 'socket.io', 'Material UI', 'jest', 'd3.js', 'Redux', 'Docker', 'Redis', 'Postgres', 'Ethereum Blockchain', 'Solidity', 'zeroEx (0x)', 'web3', 'Gitlab', 'AWS', 'lerna', 'SSR']
    },
    {
      position: 'Senior Web Developer',
      company: 'Solidbrain',
      startDate: 'Oct 2016',
      endDate: 'Nov 2017',
      location: 'Cracow',
      overview: 'Creating SPA applications in React, frontend based Outlook Addin, ASP.Net MVC applications and web animations. Consulting for clients about frontend frameworks.',
      technologies: ['React', 'Redux', 'ES6+', 'node.js', 'Fabric', 'ASP.Net MVC / Razor', 'GSAP', 'Gulp', 'webpack']
    },
    {
      position: 'Senior Web Developer, Frontend Developer',
      company: 'ABB (Asea Brown Boveri)',
      startDate: 'Mar 2013',
      endDate: 'Nov 2017',
      location: 'Cracow',
      overview: 'Development of company\'s CMS and it\'s modules: e-commerce systems, product catalogs and various solutions for many business units.',
      technologies: ['Sitefinity', 'HTML5', 'JavaScript', 'jQuery', 'jQuery UI', 'Jasmine', 'RequireJS', 'CSS3', 'SASS', 'BEM', 'RWD', 'Zurb Foundation', 'Google Tag Manager', 'Google Maps / Analytics API', 'SEO', 'C#', 'ASP.Net MVC', 'ASP.Net WebForms', 'Mocha', 'MSSQL', 'Microsoft Visual Studio (Extensions)']
    },
    {
      position: 'Web Developer',
      company: 'Freelance',
      startDate: 'Sept 2009',
      endDate: 'Feb 2013',
      location: 'Cracow, Remote',
      overview: 'Development of various web applications, intranets and portals like: system for supporting small call-centre, CRM system for phone wholesale company and event registration platform. The biggest project was a group purchasing portal integrated with offer aggregator, online pay services, rating services, affiliate networks, etc. Linux administration.',
      technologies: ['HTML5', 'JavaScript', 'jQuery', 'PHP', 'Zend Framework', 'MySQL', 'Doctrine', 'CSS3', 'SASS', 'Foundation Framework', 'Google Maps API', 'PSD2HTML', 'LAMP', 'SVN', 'Prestashop', 'Photoshop']
    },

  ],
  education: [
    {
      college: 'University Of Science and Technology (AGH)',
      location: 'Cracow, Poland',
      degree: 'Master of Science (M.Sc.), Engineer, Applied Computer Science and Engineering',
      startDate: '2006',
      endDate: '2012',
      overview: 'Modeling and Information Technology. Thesis topic: “Application of Artifical Intelligence for Stress Detection in Speech Signal”'
    },
    // {
    //   college: 'Stanislaw Konarski High School',
    //   location: 'Oświęcim, Poland',
    //   degree: 'Extended Mathematics and Computer Science, A-level equivalent',
    //   startDate: '2003',
    //   endDate: '2006',
    //   overview: 'Computer Programming Team, graduated with Honors.'
    // }
  ],
  passions2: [
    'snowkiting', 'kitesurfing', 'skiing', 'hiking', 'SF literature'
  ],
  passions: [
    {
      name: 'snowkiting',
      iconClassName: 'fas fa-wind'
    },
    {
      name: 'kitesurfing',
      iconClassName: 'fas fa-water'
    },
    {
      name: 'skiing',
      iconClassName: 'fas fa-snowflake'
    },
    {
      name: 'hiking',
      iconClassName: 'fas fa-mountain'
    },
    {
      name: 'SF literature',
      iconClassName: 'fas fa-space-shuttle'
    }
  ],
  teachings: [
    {
      name: 'Selected Topics in Blockchain Technology and Distributed Ledgers',
      overview: 'Conducting practicum classes for Computer Science students of the Jagiellonian University for a course awarded the Provost Award for Didactic Achievements',
      location: 'Cracow',
      startDate: '2019'
    }
  ],
  volunteering: [
    {
      name: 'Crypto Can for Great Orchestra of Christmas Charity',
      organisation: 'WOŚP',
      overview: 'Development of Ethereum-based virtual cryptocurrency moneybox for WOŚP',
      startDate: 'Dec 2018'
    }
  ],
  personalProjects: [
    {
      name: 'Email Obfuscator',
      overview: 'Email address code generator for protecting email from text and OCR-based crawlers.',
      startDate: 'Mar 2020',
      technologies: ['React', 'Redux', 'Material UI', 'Typescript', 'JSS']
    }
  ],
  copyright: 'Copyright © 2019. @xnyl', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#5a76b9', // Used for setting manifest and progress theme colors.
  backgroundColor: '#fff' // Used for setting manifest background color.
}
